export const endpoints = {
    landing: '/login',
    dashboard: '/',
    myJobs: '/jobs',
    careerAssist: '/careerAssist',
    postJob: '/post-job',
    shortlistCandidates: '/candidates',
    taggedCandidates: '/candidates/tagged',
    interviews: '/interviews',
    calendars: '/calendars', 
    assessments: '/assessment',
    sendAssessment: '/assessment/send',
    reports: '/reports',
    chats: '/chats',
    // prettier-ignore
    legacyRecruiterUrl: `https://admin.${process.env.REACT_APP_BASE_DOMAIN_NAME}`,
    recruiterProfile: '/account-settings',
    about: '/about-us',
    contact: '/contact-us',
    privacy: '/privacy',
    terms: '/terms-condition',
    refund: '/refund',
    managerPortal: 'https://dashboard.iimjobs.com',
    interviewMeet: '/interview/meet',
    searchUrl: `https://search.${process.env.REACT_APP_BASE_DOMAIN_NAME}`,
    premiumUpgraded: `/premiumJobPosted`,
    calculusUrl: `https://calculus.${process.env.REACT_APP_BASE_DOMAIN_NAME}`,
    myCourses: '/courses',
    postCourse: '/post-course',
    bulkResumeStatus: '/bulk-resume-status',
    premiumJobProgress: '/premium-progress',
    plans: '/plans',
    nonPremiumJobPosted: '/upgradetopremium',
    CompanyRegister: '/registerCompany',
    requestCallback: '/contact-us',
    twoStepAuth: '/two-step-authentication',
};
