let searchUrl = process.env.REACT_APP_SEARCH_URL;
let calculusUrl = process.env.REACT_APP_CALCULUS_URL;

export const productItems = {
    title: 'Products',
    link: '',
    // onClick: handler => handler.bind(null, 'myjobs'),
    isReact: false,
    isHidden: false,
    children: [
        {
            title: 'Search Resume',
            link: searchUrl,
            onClick: handler => handler.bind(null, 'search'),
        },
        {
            title: 'Calculus - Salary Benchmarking Tool',
            link: calculusUrl,
            onClick: handler => handler.bind(null, 'calculus'),
        },
    ],
};
