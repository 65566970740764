import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../Button';
import { Modal } from '../../../Modal';
import { postLeads } from '../../../../../services/postLeads';
import { updateProfile } from '../../../../../services/updateProfile';
import { sendEvent } from '../../../../../utils/sendEvent';
import { organizationLocation } from '../../../../../constants/organizationLocation';
import { Dropdown } from '../../../Dropdown';
import { FieldError } from '../../../FieldError';
import { noOfEmployees } from '../../../../../constants/noOfEmployees';

import './styles.scss';
import * as toast from '../../../../containers/Toast';

export const CalculusModal = props => {
    const { recruiterId, profile } = props;
    const localStorageItem = localStorage.getItem('sessionStatus');
    let storage = localStorageItem
        ? JSON.parse(localStorage.getItem('sessionStatus'))
        : {};
    const data = {
        recruiterId: recruiterId,
        additionalInfo: {
            enquiry_type: 'New',
        },
    };
    const salesMail = `Sales@${process.env.REACT_APP_BASE_DOMAIN_NAME}`;
    const [loading, setLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(true);
    const [validations, setValidations] = useState({});
    const [orgLocation, setOrgLocation] = useState(0);
    const [companySize, setCompanySize] = useState(0);
    const [apiResFail, setApiResFail] = useState(false);

    const newData = {
        ...data,
        productType: '2',
        activity: '1',
        source: '1',
    };

    useEffect(() => {
        if (!storage['calculusClick']) {
            try{
                postLeads(recruiterId, newData);
                storage = { ...storage, calculusClick: true };
                localStorage.setItem('sessionStatus', JSON.stringify(storage));
            }
            catch(e) {
                console.log(e);
            }
        }
        // console.log('Calculus Modal Opened By React');
    }, [recruiterId]);
    const demoLabel = `recid=${recruiterId},origin=Navbar`;

    async function requestDemo() {

        const { pass, vals } = validate();
        if (pass) {
            setLoading(true);
            setShowMsg(false);

            try {
                if (orgLocation)
                    await updateProfile(recruiterId, { location: orgLocation });
                await sendEnquiry();
            } catch (err) {
                console.log(err);
                setLoading(false);
                setApiResFail(true);
            }
        } else {
            setValidations(vals);
            return;
        }
    }


    async function sendEnquiry() {
        setLoading(true);
        try {
            const enquiryData = {
                ...data,
                productType: '2',
                activity: '2',
                source: '1',
                companyType: profile.recruiterType,
                companySize
            };
            sendEvent('clickCalculusDemo', demoLabel);
            await postLeads(recruiterId, enquiryData);
            storage = { ...storage, calculusEnquiry: true };
            localStorage.setItem('sessionStatus', JSON.stringify(storage));
            setLoading(false);
        } catch (err) {
            if(err.response.data && err.response.data.code == 4040){
                toast.warning("Please try after sometime");
            }
            console.log(err);
            setLoading(false);
        }
    }

    function validate() {
        const vals = {
            orgLocationReq:
                (!orgLocation || parseInt(orgLocation) <= 0) &&
                    (!profile.location || parseInt(profile.location) <= 0)
                    ? 'Please select Organization Location'
                    : null,
            companySizeReq:
                (!companySize) && (!profile.companySize || parseInt(profile.companySize) <= 0)
                    ? 'Please select company size'
                    : null,
        };

        let pass = true;
        for (let key of Object.keys(vals)) {
            if (vals[key]) {
                pass = false;
                break;
            }
        }
        return {
            pass,
            vals,
        };
    }

    function onLocationChange(val) {
        setOrgLocation(val);
    }

    function onCompanySizeChange(val) {
        setCompanySize(val);
    }

    let text = "";
    if ((!profile.location || +profile.location <= 0) && !profile.companySize) {
        text = "company size and work location";
    } else if (!profile.location) {
        text = "work location";
    } else if (!profile.companySize) {
        text = "company size";
    }

    return (
        <Modal onClose={props.onClose} classes={['calculusModalWrapper']}>
            <div className="calculusModal" data-testid="calculusModal">
                <div className="modal_content modal_left">
                    <div className="Modal_heading_calculus">
                        CALCULUS - TALENT MAPPING AND INTELLIGENCE TOOL
                    </div>
                    <div className="Modal_subPara_calculus">
                        <div className="left_calculus_para">Q.</div>
                        <div className="right_calculus_para">
                            Do you have a compensation plan?
                            <br />
                            How much should you pay for profiles with certain
                            skills in your company?
                            <br />
                            What about profile density across the different
                            geographical locations?
                            <br /> Do you frequently come across such questions?
                        </div>
                    </div>
                    <div className="subHeading">
                        Our compensation reports give you an accurate picture
                        for your Premium Hiring:
                    </div>
                    <div className="section">
                        <table>
                            <tbody>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/tick-green.png"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points">
                                        Get Current and expected salary ranges
                                        for quick decision making
                                    </td>
                                </tr>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/tick-green.png"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points ">
                                        Bridge your gender pay differences
                                    </td>
                                </tr>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/tick-green.png"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points">
                                        Experience-based Salary Insight for
                                        skills and titles in multiple markets
                                    </td>
                                </tr>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/tick-green.png"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points">
                                        Understand the Geographical Talent
                                        Hotspots
                                    </td>
                                </tr>
                                <tr className="info_text calculusRowHeight">
                                    <td className="indexNum">
                                        <img
                                            src="/static/images/tick-green.png"
                                            className="calculus_tick"
                                            alt="*"
                                        />
                                    </td>
                                    <td className="info_calculus_points">
                                        Watch out for Salary trends over time
                                        such as Attrition and Average Tenure
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {!storage['calculusEnquiry'] && showMsg ? <>
                                {text ? (
                                    <div className="subHeading">
                                        Please help us with your {text} to
                                        connect you with concerned team member
                                        at earliest:
                                    </div>
                                ) : null}
                            {
                                (!profile.companySize) && (
                                    <div className="search-location">
                                        <Dropdown
                                            type="text"
                                            onChange={onCompanySizeChange}
                                            label="Company Size*"
                                            classes={['companySize']}
                                            name="companySize"
                                            options={noOfEmployees}
                                            blackCaret
                                            defaultValue={companySize}
                                            errorMsg={validations.companySizeReq}
                                        />
                                    </div>)
                            }
                            {
                                (!profile.location || parseInt(profile.location) <= 0) && (
                                    <div className="search-location">
                                        <Dropdown
                                            type="text"
                                            onChange={onLocationChange}
                                            label="State*"
                                            classes={['orgLocation']}
                                            name="orgLocation"
                                            options={organizationLocation}
                                            blackCaret
                                            defaultValue={orgLocation}
                                            errorMsg={validations.orgLocationReq}
                                        />
                                    </div>)
                            }
                        </> : null}
                        <div className="subHeading calculus_info_subheading">
                            Are you still missing on Talent Mapping and
                            Intelligence Tool?
                        </div>
                        {!storage['calculusEnquiry'] ? (
                            <div className="success_message_wrapper">
                                <Button
                                    isLoading={loading}
                                    onClick={requestDemo}
                                    classes={['green']}
                                    type={'calculusButton'}
                                    dataTestid="demoButtonCalculus"
                                >
                                    Request a Callback
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    {storage['calculusEnquiry'] ? (
                        <p
                            className="info-text success_message_calculus"
                            data-testid="calculusSuccessMsg"
                        >
                            Thank you for showing interest in our Calculus
                            product. Our team will be in touch with you soon!
                        </p>
                    ) : null}
                </div>
                <div className="modal_right">
                    <div>
                        <div className="modal_right_content_calculus">
                            <div>
                                <div className="calculus_videoArrowImgContainer">
                                    <img
                                        src="/static/images/down-arrow-bent.png"
                                        className="calculus_videoArrowImg"
                                        alt=""
                                    />
                                </div>
                                <div className="calculus_videoMsgContainer">
                                    Watch this 3 min video for better
                                    understanding
                                </div>
                            </div>
                            <iframe
                                className="calculus_videoIframe"
                                src="https://www.youtube.com/embed/0QzRC-1GonM"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                data-testid="iframeCalculus"
                            ></iframe>
                            <div className="info_text info_calculus_contactHeading">
                                Connect with us at:
                            </div>
                            <div className="info_text info_calculus_contactInfo">
                                <a href={`mailto:${salesMail}`}>{salesMail}</a>
                            </div>
                            <div className="info_text info_calculus_contactInfo">
                                Toll Free No.: 1800-103-7344
                            </div>
                            <div className="info_text info_calculus_contactInfo">
                                (9.30 AM to 6 PM, Mon-Sat)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

CalculusModal.propTypes = {
    onClose: PropTypes.func,
};
