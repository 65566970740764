import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../Button';
import { Modal } from '../../../Modal';
import { postLeads } from '../../../../../services/postLeads';
import { updateProfile } from '../../../../../services/updateProfile';
import { sendEvent } from '../../../../../utils/sendEvent';
import { organizationLocation } from '../../../../../constants/organizationLocation';
import { noOfEmployees } from '../../../../../constants/noOfEmployees';
import { Dropdown } from '../../../Dropdown';
import { FieldError } from '../../../FieldError';
import { selectProfile } from '../../../../selector';
import { useSelector } from 'react-redux';
import * as toast from '../../../../containers/Toast';


import './styles.scss';

const searchResumeConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/searchResumeConfig.json`);

export const SearchModal = props => {
    const baseDomainName = process.env.REACT_APP_BASE_DOMAIN_NAME;
    let storage = JSON.parse(localStorage.getItem('sessionStatus')) || {};
    const { recruiterId } = props;
    const data = {
        recruiterId: recruiterId,
        additionalInfo: {
            enquiry_type: 'New',
        },
    };

    const salesEmail = `sales@${baseDomainName}`;

    const profile = useSelector(selectProfile);
    const [loading, setLoading] = useState(false);
    const [showMsg, setShowMsg] = useState(true);
    const [validations, setValidations] = useState({});
    const [orgLocation, setOrgLocation] = useState(0);
    const [companySize, setCompanySize] = useState(0);
    const [apiResFail, setApiResFail] = useState(false);

    const newData = {
        ...data,
        productType: '1',
        activity: '1',
        source: '1',
    };

    useEffect(() => {
        if (!storage['searchClick']) {
            try{
                postLeads(recruiterId, newData);
                storage = { ...storage, searchClick: true };
                localStorage.setItem('sessionStatus', JSON.stringify(storage));
            }
            catch(e) {
                console.log(e);
            }
        }
    }, [recruiterId]);

    const demoLabel = `recid=${recruiterId},origin=Navbar`;

    async function requestDemo() {

        const { pass, vals } = validate();
        if (pass) {
            setLoading(true);
            setShowMsg(false);

            try {
                if (orgLocation)
                    await updateProfile(recruiterId, { location: orgLocation });
                await sendEnquiry();
            } catch (err) {
                console.log(err);
                setLoading(false);
                setApiResFail(true);
            }
        } else {
            setValidations(vals);
            return;
        }
    }

    async function sendEnquiry() {
        setLoading(true);
        try {
            const newData = {
                ...data,
                productType: '1',
                activity: '2',
                source: '1',
                companyType: profile.recruiterType,
                companySize
            };
            sendEvent('clickSearchResumeDemo', demoLabel);
            await postLeads(recruiterId, newData);
            storage = { ...storage, searchEnquiry: true };
            localStorage.setItem('sessionStatus', JSON.stringify(storage));
            setLoading(false);
        } catch (err) {
            if(err.response.data && err.response.data.code == 4040){
                toast.warning("Please try after sometime");
            }
            console.log(err);
            setLoading(false);
        }
    }

    function validate() {
        const vals = {
            orgLocationReq:
                (!orgLocation || parseInt(orgLocation) <= 0) &&
                    (!profile.location || parseInt(profile.location) <= 0)
                    ? 'Please select Organization Location'
                    : null,
            companySizeReq:
                (!companySize) && (!profile.companySize || parseInt(profile.companySize) <= 0)
                    ? 'Please select company size'
                    : null,
        };

        let pass = true;
        for (let key of Object.keys(vals)) {
            if (vals[key]) {
                pass = false;
                break;
            }
        }
        return {
            pass,
            vals,
        };
    }

    function onLocationChange(val) {
        setOrgLocation(val);
    }

    function onCompanySizeChange(val) {
        setCompanySize(val);
    }

    let text = "";
    if ((!profile.location || +profile.location <= 0) && !profile.companySize) {
        text = "company size and work location";
    } else if (!profile.location) {
        text = "work location";
    } else if (!profile.companySize) {
        text = "company size";
    }

    console.log(searchResumeConfig.heading);
    return (
        <Modal
            classes={["search-modal"]}
            onClose={props.onClose}
            header={<div className="modal-heading">Search Resume</div>}
        >
            {!storage['searchEnquiry'] ? (
                <div className="modal-text">
                    <div className="modal-text-heading">
                        {searchResumeConfig.heading}
                    </div>
                    {searchResumeConfig.content.map(item => {
                        return (
                            <div className='modal-text-item'>
                                <i className='icon-tick'></i>
                                {item}
                            </div>
                        );
                    })}
                </div>
            ) : null}
            {!storage['searchEnquiry'] && showMsg ? <>
                <div className="modal-text">
                    Please help us with your {text} to connect
                    you with concerned team member at earliest:
                </div>
                {
                    (!profile.companySize) && (
                        <div className="search-location">
                            <Dropdown
                                type="text"
                                onChange={onCompanySizeChange}
                                label="Company Size*"
                                classes={[
                                    'companySize'
                                ]}
                                name="companySize"
                                options={noOfEmployees}
                                blackCaret
                                defaultValue={companySize}
                                errorMsg={validations.companySizeReq}
                            />
                        </div>)
                }
                {
                    (!profile.location || parseInt(profile.location) <= 0) && (
                        <div className="search-location">
                            <Dropdown
                                type="text"
                                onChange={onLocationChange}
                                label="State*"
                                classes={[
                                    'orgLocation',
                                    `${apiResFail && 'error-border'}`,
                                ]}
                                name="orgLocation"
                                options={organizationLocation}
                                blackCaret
                                defaultValue={orgLocation}
                                errorMsg={validations.orgLocationReq}
                            />
                        </div>)
                }
            </> : null}
            <div className="section">
                {!storage['searchEnquiry'] ? (
                    <Button
                        isLoading={loading}
                        onClick={requestDemo}
                        classes={['green']}
                    >
                        Request a Callback
                    </Button>
                ) : null}
                {storage['searchEnquiry'] ? (
                    <p className="hidden" id="success_message">
                        Thank you for your interest in our Search Resume
                        product. Our team will be in touch with you shortly!
                    </p>
                ) : null}
                <br />
                <br />

                <div className="info-text bold">
                    Alternatively, you can connect with us at:
                </div>
                <div className="info-text">
                    Email: <a href={`mailto:${salesEmail}`}>{salesEmail}</a>
                </div>
                <div className="info-text">Toll Free No.: 1800-103-7344</div>
                <div className="info-text">(9.30 AM to 6 PM, Mon-Sat)</div>
            </div>
        </Modal>
    );
};

SearchModal.propTypes = {
    onClose: PropTypes.func,
};
