import React, { useState } from 'react';

import './styles.scss';

const landingConfig = require(`../../../../../constants/${process.env.REACT_APP_BASE_DOMAIN}/landingConfig.json`);

const NO_OF_TESTIMONIALS = 3;

export const Testimonial = () => {
    const [currentTestimonailNo, setCurrentTestimonialNo] = useState(1);
    const testimonials = landingConfig['testimonials'];
    const currentTestimonial = testimonials[currentTestimonailNo - 1];

    const changeTestimonail = change => {
        if (
            currentTestimonailNo + change < 1 ||
            currentTestimonailNo + change > NO_OF_TESTIMONIALS
        ) {
            return;
        }
        setCurrentTestimonialNo(currentTestimonailNo + change);
    };

    if (!testimonials.length) {
        return <></>;
    }

    return (
        <section className="testimonial-section">
            <div className="testimnonial-content">
                <div className="testimonail-text">
                    {currentTestimonial.text}
                </div>
                <div className="slide-testmonial-btn">
                    <i
                        className={`icon-right_arrow slide-left ${
                            currentTestimonailNo === 1 ? 'disabled' : ''
                        }`}
                        onClick={() => changeTestimonail(-1)}
                    ></i>
                    <i
                        className={`icon-right_arrow slide-right ${
                            currentTestimonailNo === NO_OF_TESTIMONIALS
                                ? 'disabled'
                                : ''
                        }`}
                        onClick={() => changeTestimonail(1)}
                    ></i>
                </div>
                <div className="brand-logo">
                    <img src={currentTestimonial.logoUrl} alt="" />
                </div>
                <div className="current-selected-icons">
                    {/* <img src="" alt="sliding-icon" />
                    <img src="" alt="sliding-icon" />
                    <img src="" alt="sliding-icon" /> */}
                    {Array.from(
                        { length: NO_OF_TESTIMONIALS },
                        (_, i) => i + 1,
                    ).map(value => {
                        return (
                            <div
                                className={`current-selected-icon ${
                                    currentTestimonailNo === value
                                        ? 'selected'
                                        : ''
                                }`}
                            >
                                {' '}
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
